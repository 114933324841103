@import url("https://fonts.googleapis.com/css2?family=Exo&family=Lato:wght@400;700&display=swap");
@import "../node_modules/normalize.css";
@import "./variables.scss";
@import "../node_modules/@blueprintjs/core/lib/css/blueprint.css";
@import "../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css";

html,
body {
    background-color: $swarm-background;
}

#root {
    display: flex;
    flex-direction: column;
}

.help-text {
    font-size: 12px;
}

//
// Blueprint style overrides (variable overrides go in ./variables.scss)
//
.bp4-label {
    font-size: $pt-font-size-small;
    font-weight: 600;

    // This is primarily used by form groups for labelInfo --
    // muted text inside a label shouldn't also be bolded
    .bp4-text-muted {
        font-weight: normal;
    }
}

.bp4-code-block {
    white-space: pre-wrap;
}

h1.bp4-heading,
h2.bp4-heading {
    font-family: Exo, Lato, system-ui, sans-serif;
    font-weight: 400;
}

/* used on login form. Remove white drop shadow border */
.bp4-dark .bp4-input {
    box-shadow: none;
}

h3.bp4-heading,
h4.bp4-heading {
    font-family: Lato, system-ui, sans-serif;
    font-weight: 700;
}

// Move the shadow on HTMLTables from the top of the first body row to the bottom of the header
// row. This means tables without headers don't have a line at the top, and when we make the header
// sticky, the shadow doesn't scroll away.
// Also add this to the top of the tfoot.
table.bp4-html-table {
    thead tr:last-child {
        td,
        th {
            box-shadow: inset 0 -1px 0 0 $pt-divider-black;
        }
    }
    tbody tr:first-child {
        td,
        th {
            box-shadow: none !important;
        }
    }
    tfoot tr:first-child {
        td,
        th {
            box-shadow: inset 0 1px 0 0 $pt-divider-black;
        }
    }
}

$standard-tag-height: 20;

// only show 5.5 tags in the multiselect dropdown. Users can see more by scrolling
// we want to show .5 extra so it's clear the area is scrollable by always having a tag partially visible
.bp4-multi-select > .bp4-tag-input-values {
    max-height: round($standard-tag-height * 5.5) * 1px;
    overflow: auto;
}
